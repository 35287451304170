.quoteContainer {
  /* height: 70vh; */

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  gap: 50px;
}

.Qimage {
  max-width: 140px;
  margin-bottom: 4px !important;
}

.Qp {
  margin: '0px';
  color: #c6884a;
  text-align: center;
  max-width: 304px;
  font-family: CustomFont3;
}

.quoteHeadline {
  font-size: 2rem;
  /* text-transform: uppercase; */
  text-align: center;
  font-family: QuoteFont;
  margin: 0 !important;
}

.quoteHeadline span {
  font-family: CustomFont2;
  font-size: 1.9rem;
  margin: 0 !important;
  text-transform: capitalize;
}

@media (max-width: 425px) {
  .quoteContainer {
    margin-top: 5px;
    gap: 50px;
    justify-content: center;
  }

  .quoteHeadline {
    font-size: 1.5rem;
  }

  .quoteHeadline span {

    font-size: 0.9rem;

  }
}