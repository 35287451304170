.image-container {
    width: 100%;
  }
  
  .responsive-image {
    width: 100%;
    object-fit: cover;
    
  }
  .img-rotate{
    transform: rotate(180deg);
  }
  @media (max-width: 768px) {
    /* For mobile screens */
    .responsive-image {
      height: 500px !important; /* Override height on mobile to 500px */
    }
  }
  