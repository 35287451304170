.bgContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transform: scale(1.1); /* Start with a slightly larger image */
  transition: opacity 1.5s ease-in-out, transform 2s ease-in-out; /* Smooth transitions */
  z-index: 0;
}

.bg.active {
  opacity: 1;
  transform: scale(1); /* Smooth zoom-out effect */
  z-index: 1;
}

.loader {
  position: absolute; /* Center loader */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center horizontally and vertically */
  font-size: 2rem; /* Adjust loader size */
  color: black; /* Loader text color */
  z-index: 2; /* Ensure loader is above other elements */
}


.headingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.heading {
  color: white;
  font-size: 2rem;
  font-family: CustomFont2 !important;
  font-weight: bold;
}

.arrow {
  position: absolute;
  bottom: 15px;
  left: 50%;
  font-size: 2.5rem;
  color: var(--primary-color);
  z-index: 1;
  transition: transform 0.3s ease-in-out;
}

.arrow:hover {
  transform: translateY(-5px); /* Hover effect for the arrow */
}

/* Media Queries */
@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }

  .arrow {
    font-size: 2rem;
  }
}

@media (max-width: 425px) {
  .headingContainer {
    left: 10%;
  }

  .heading {
    font-size: 0.9rem;
  }

  .arrow {
    font-size: 1.5rem;
  }
}
