.aboutContainer {
  overflow: hidden;
}
.gifContainer {
  width: 100%;
}
.video {
  width: 100vw;
  height: 60vh;
  object-fit: cover;
}
