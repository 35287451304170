.affirmation-container {
  width: 70vw;
  margin: 0 auto;
}

.km {
  text-align: center;
  font-family: 'CustomFont3';
  color: rgb(144, 144, 144);
  text-transform: none;
  font-size: 24px;
  margin-top: 50px;
}

.affirmation {
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
}

.affirmation h4 {
  text-align: center;
  color: #545454;
    letter-spacing: 150;
    line-height: 1.79;
    text-align: center;
   font-size: 1.1rem;
    font-family: CustomFont3;
}

.affirmation p {
  color: rgb(79, 79, 79);
  font-size: 14px;

  text-align: center;
}

.signature {
  margin-top: 1rem;

  color: rgb(144, 144, 144);
}


/* Ensure responsiveness */
@media (max-width: 1024px) {
  .affirmation-container {
    width: 80vw;
  }

  .km {
    font-size: 20px;
  }

  .affirmation h4 {
    font-size: 16px;
  }

  .affirmation p {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .affirmation-container {
    width: 90vw;
  }

  .km {
    font-size: 18px;
  }

  .affirmation h4 {
    font-size: 14px;
  }

  .affirmation p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .affirmation-container {
    
  }

  .km {
    font-size: 18px;
    margin-bottom:0px !important;
    margin-top: 0px !important;
  }

  .affirmation h4 {
    font-size: 14px;
  }

  .affirmation p {
    font-size: 11px;
  }

  .affirmation {
    padding: 0.5rem 1rem;
  }
}