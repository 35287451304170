:root

/* {
    --bg-color: #0c0c0c;
    --primary-color: #0d0d0d;
    --secondary-color: #fff;
    --text-color: #fff;
    --text-color-2: #fff;
    --overlay-color: rgb(12 12 12 / 63%);
}

[data-theme="light"] */
  {
  --bg-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #000;
  --text-color: #000;
  --text-color-2: #000;
  --overlay-color: rgb(255 255 255 / 70%);
}

@font-face {
  font-family: CustomFont;
  src: url("/src/assets/Acumin-RPro.woff");


}

@font-face {
  font-family: CustomFont2;
  src: url("/src/assets/kudryashev/main.otf") format('otf');


}

@font-face {
  font-family: "Quarto-Light";
  src: url("https://db.onlinewebfonts.com/t/0228a50c5e270e5739807b9f8b0f7e2e.eot");
  src: url("https://db.onlinewebfonts.com/t/0228a50c5e270e5739807b9f8b0f7e2e.eot?#iefix") format("embedded-opentype"),
       url("https://db.onlinewebfonts.com/t/0228a50c5e270e5739807b9f8b0f7e2e.woff") format("woff"),
       url("https://db.onlinewebfonts.com/t/0228a50c5e270e5739807b9f8b0f7e2e.woff2") format("woff2"),
       url("https://db.onlinewebfonts.com/t/0228a50c5e270e5739807b9f8b0f7e2e.ttf") format("truetype"),
       url("https://db.onlinewebfonts.com/t/0228a50c5e270e5739807b9f8b0f7e2e.svg#Quarto-Light") format("svg");
}

@font-face {
  font-family: "Silk Serif ExtraLight";
  src: url("./assets/Silk Serif ExtraLight.otf") format("opentype");

}
@font-face {
  font-family: "Silk Serif Light Italic";
  src: url("./assets/SilkSerif-LightItalic.woff2") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: CustomFont3;
  src: url("/src/assets/Freight\ Big\ Light\ Italic\ SC.otf") format('otf');



}
@font-face {
  font-family: BannerFont;
  src: url("/src/assets/grayscale_signature/Grayscale\ Signature.ttf");


}
@font-face {
  font-family: QuoteFont;
  src: url("/src/assets/Lobster/Lobster-Regular.ttf");


}
@font-face {
  font-family: MomentFont;
  src: url("/src/assets/jenriv/JenrivTitling-LightItalic.otf");


}
html,
body {
  height: 100%;
}

body ::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--bg-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: CustomFont !important;
  /* border-left: 10px solid var(--primary-color);
    border-right: 10px solid var(--primary-color); */
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Marcellus", serif;
}

a,
a:hover {
  color: var(--text-color);
  text-decoration: none !important;
}

p {
  word-break: break-word;
  /* hyphens: auto; */
  letter-spacing: 150;
  line-height: 1.79;
}

.ovhidden {
  overflow: hidden;
}

.text_2,
.text_2:hover {
  color: var(--text-color-2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor__dot div {
  z-index: 999999 !important;
}

.cursor__dot div:last-child {
  filter: invert(1);
  background-color: var(--primary-color) !important;
}

.cursor__dot div:first-child {
  filter: invert(1);
  background-color: var(--overlay-color) !important;
}

.color_pr {
  color: var(--primary-color) !important;
}

.color_sec {
  color: var(--secondary-color);
}

.navItem{
color: #000;
}
