@media only screen and (max-width: 991px) {
    
  /* .s_c {
    padding-top: 40px;
    
} */
}
  .s_c {
        overflow-x:hidden ;
        
    }
.page-enter {
    transform: translateY(100%);
}

.page-enter-active {
    transform: translateY(0%);
    transition: all 400ms ease-out;
}

.page-exit {
    transform: translateY(0%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.page-exit-active {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-130%);
    transition: all 400ms ease-out;
}

.preloader {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .preloader-text {
    font-size: 48px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  
  .typewriter-element {
    opacity: 1;
  }
  .fade-enter {
    transform: translateX(100%);
  }
  
  .fade-enter-active {
    transform: translateX(0%);
    transition: 1s ease all;
  }
  
  .fade-exit {
    transform: translateX(0%);
  }
  
  .fade-exit-active {
    transform: translateX(100%);
    transition: 1s ease all;
  }
  
  .scrollTop {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .scrollTop:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .scrollTop svg {
    color: #333;
  }
  :root {
    letter-spacing :1px;
  }

  .fade-out-enter {
    opacity: 1;
  }
  
  .fade-out-enter-active {
    opacity: 0;
    transition: opacity 1000ms;
  }
  
  .fade-out-exit {
    opacity: 0;
  }
  
  .fade-out-exit-active {
    opacity: 1;
    transition: opacity 1000ms;
  }
  .preloader.fade-out {
    opacity: 0;
    transition: opacity 1s ease-out;
  }
  .preLoaderImg {
    width: 13vw;
  }

  
  @media (max-width: 425px) {
    .preLoaderImg {
      width: 45vw;
    }
  }
.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 6.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}