.followContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageDiv {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  height: 100%;
  gap: 10px;
  max-width: 1177px;

  @media (max-width: 961px) {
    grid-template-columns: 1fr;
  }
}

.followSection {
  padding: 2em;
}

.followImg {
  width: 155px;
  height: 155px;
  object-fit: cover;

  text-align: center;
}

.fm {
  text-align: center;
  font-weight: 600;
  position: absolute;
  color: black;
  background-color: rgb(240, 240, 240);
}

.space {
  width: 48px;
  height: 1px;
}

.link {
  text-transform: uppercase;
  font-size: 12px;
  transition: border-color 0.15s ease-out, color 0.15s ease-out;
}

.link a {
  display: inline-block;
  position: relative;
  color: inherit;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.link a:hover {
  color: #c6884a
}

.space {
  width: 60px;
}
@media (max-width: 600px) {
  .imageDiv {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    gap:2px/* One column layout on small screens */
  }

  .followImg {
    width: 40px;
    height: 60px; /* Adjust image size on small screens */
  }
  .followSection {
    padding: 0;
    font-size: 8px !important;
    text-align: center;
  }
}