.storyTellerContainer {
    width: 100%;
    background-color: #F6F4F2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5vh;
    margin-top: 5rem;
}

.image-slider {
    max-width: 362px;
}

.storyTellerContainer1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10vw;
}

.image {
    max-width: 360px;
}

.paras {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.Sp {
    color: rgba(102, 102, 102, 1);
    letter-spacing: 0.07em;
    line-height: 20px;
    text-align: center;
    max-width: 20vw;
    font-family: 'Quarto Light', serif;
    font-weight: 400;
}
.Sp1{
    font-weight: bold;
}

.Sp2 {
    font-size: 15px !important;
}

.km {
    color: #C6884A;
}

@media (max-width: 925px) {
    .Sp {
        max-width: 35vw;
    }

    .image {
        max-width: 270px;
    }
}

@media (max-width: 445px) {
    .storyTellerContainer {
        flex-direction: column;
        margin-top: 3rem;
    }

    .image {
        max-width: 260px;
    }

    .Sp {
        max-width: 70vw;
    }

    .storyTellerContainer1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .image-slider {
        width: 100%;
        /* Ensure the slider takes the full width */
    }

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px;
        /* Adjust height as needed */
    }

    .image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        /* Ensure the image covers the container */
    }

}