.question {
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;


}
.singleQuestion {
  text-align: center;
}
.singleQuestion h4 {
    text-transform: uppercase;
    font-size: 1.3rem;
}
.singleQuestion p {
    font-size: 16px;
}