.form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contact__form {
  max-width: 600px;

}

.left-image {
  width: 50%;
  /* padding-right: 20px; */
}

.right-form {
  width: 50%;
}


.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-swipeable-view-container{
  will-change: auto !important;
}

/* .left-image {
  flex: 1;
  position: relative;
} */

.form-group .form-color{
  background-color: rgba(249,247,244,0.5);
}
.btn.focus, .btn:focus{
  box-shadow: none;
}

.form-image {
  width: 90%;
  /* height: 100%; */
  height: 85vh; 
  object-fit: cover;
}

.right-form {
  flex: 1.5;
}

.text-danger {
  color: red !important;
  font-weight: 100;
  font-size: 12px;
}

.is-invalid {
  border-color: red;
}

.form-control {
  margin-bottom: 5px !important;
}

/* Input Field Styling */
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: none;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  background-color: transparent;
  margin-bottom: 20px;
  border-radius: 0;
  color: rgba(52, 52, 52, 1);
  text-align: left;
  font-family: 'Silk Serif Light Italic';
  font-weight: 400;
  font-style: normal;
}

.btn-cls{
  color: rgba(153, 134, 117, 1);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 11px;
  text-align: left;
  font-family: 'Futura PT Demi';
  font-weight: 400;
  font-style: normal;
  background-color: transparent !important;
  border-bottom: 1px solid darkgray !important;
  border-radius: 0;
}

.form-group label{
  font-family: 'Silk Serif Light Italic';
}

.form-control:focus{
  box-shadow: none !important;
}
.form-control:focus-visible{
  outline: none !important;
}

input:-internal-autofill-selected{
  background-color: transparent !important;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #999;
  font-family: "Silk Serif Light Italic", serif;
}

.form-group textarea {
  height: 150px;
}

/* Button Styling */
.submitBtn {
  background-color: #efefef;
  border: none;
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
}

.submitBtn:hover {
  background-color: #ddd;
}

/* Remove default input focus styles and add a subtle focus effect */
.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-bottom: 1px solid #aaa;
}

/* Alert Styling */
.co_alert {
  font-size: 16px;
  padding: 15px;
  border: none;
  background-color: #f8d7da;
  color: #721c24;
}

/* Responsive design */
@media (max-width: 768px) {
  .form-container {
    flex-direction: column;
  }
  .left-image{
    width: 100%;
  }
  .right-form {
    width: 100%;
    padding: 20px;
    margin-top: 30px;
  }
  .form-image{
    width: 100%;
    height: 100%;
  }
  .contact__form{
    max-width: 100%;
  }
}
@media (min-width:768px) and (max-width:1000px) {
  .form-image{
    height: 90vh;
  }
}

.mobile-label {
  display: none;
}
.hide-m{
  display: none;
}

/* Show the label only on screens with a max width of 768px (common breakpoint for mobile) */
@media (max-width: 768px) {
  .mobile-label {
    display: block;
    font-size: 16px;
    padding: 0 18px;
    font-family: "Silk Serif Light Italic", serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
    position: absolute;
    top: 7px;
  }
  .hide-m{
    display: block;
  }
  .hide-d{
    display: none;
  }
}



/* Target placeholder for custom-placeholder class */
.custom-placeholder::placeholder {
  color: #161616 !important; /* Change this to desired color */
  opacity: 1;
}

/* For Internet Explorer */
.custom-placeholder::-ms-input-placeholder {
  color: #999  !important;
}

/* For Edge */
.custom-placeholder::-ms-input-placeholder {
  color: #999 !important;
}

/* For Firefox */
.custom-placeholder:-moz-placeholder {
  color: #999;
  opacity: 1;
}

/* For Safari */
.custom-placeholder:-webkit-input-placeholder {
  color: #999;
}
