.collabContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    padding-bottom: 0px !important;
  }
  
  .inner {
    display: flex;
   
    width: 100%;
    justify-content: center;
    gap: 2vw;
    align-items: center;
  }
  
  .innerImg {
    display: flex;
    flex-wrap: wrap;
  }
  
  .gImg {
    max-width: 185px;
    height: 100%;
  }
  
  .imagesGrid {
    display: grid;
    grid-template-columns: auto auto ;
    

    gap: 20px;
  }
  
  .gp {
    
    max-width: 440px !important;
  }
  @media screen and (max-width: 780){
    .inner {
      flex-direction: column;
      gap: 10vw;

    }
    
  }
 
  @media screen and (max-width: 1000px) {
    .inner {
      flex-direction: column;
      gap: 10vw;

    }
  }
  
  @media screen and (max-width: 580px) {
    .collabContainer {
      gap:8vh
    }
    .inner {
      flex-direction: column;
      gap: 10vw;

    }
    .gImg {
      max-width: 140px;
      height: 100%;
    }
  }
  