.prosonalized{
    font-style: italic !important;
    font-size: 20px !important;
}

@media (max-width: 768px){
    .contentContainer{
        width: "300px",
    }
    section{
        margin-left: 0;
    }
}