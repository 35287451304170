.questionSection {
    margin-bottom: 10vh;
}
@media (max-width: 480px) {
   
    .faqHeading {
      font-size: 18px;
      margin-bottom:0px !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  
  }