/* styles.css */


/* .masnoryContainer{
  padding-right: 10rem !important;
  padding-left: 10rem !important;
} */
.masonry-img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 3/4; /* adjust to the desired aspect ratio */
}

@media (max-width: 425px) {
  .masnoryContainer{
    padding-right:2rem !important;
    padding-left: 2rem !important;
  }
}



.masonryContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  margin: -5px; /* Adjust for gutter */
}

.masonryContainer img {
  margin: 5px !important; /* Gutter space */
  object-fit: cover !important; /* Cover to maintain aspect ratio */
  border-radius: 8px !important; /* Optional styling */
}

.masonryContainer .masonryItem {
  width: calc(50% - 10px); /* Two images per row */
}

@media (min-width: 750px) {
  .masonryContainer .masonryItem {
    width: calc(33.33% - 10px) !important; /* Three images per row */
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  cursor: pointer;
  z-index: 1050; /* Ensure it appears above the modal content */
}

.custom-modal {
  background-color: transparent; /* Make modal background transparent */
}

.custom-modal-body {
  background-color: transparent; /* Make modal body transparent */
  padding: 0; /* Remove padding */
  position: relative; /* For the close button positioning */
}

.modal-overlay {
  position: fixed; /* Position it fixed to cover the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Black transparent background */
  z-index: 1040; /* Ensure it is above other content */
}
