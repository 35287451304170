.site__header {

  background-color: #d3d6d2;
  display: flex;
  justify-content: end;
  padding-left: 10px;
  padding-right: 10px;
}

.site__headerDark {

  background-color: rgba(0, 0, 0, .18);
  display: flex;
  justify-content: end;
  padding-left: 10px;
  padding-right: 10px;
}

.headerContainer {
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0px 20px 0px;

  max-height: 90px
}

.headerContainerDark {
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;



}

.close-icon {
  color: #000 !important;
  fill: #000 !important;
}

.b {
  top: 20px;
  right: 45%;
}

.logo img {
  max-width: 80px;
  margin: 4px;
}

.dark {
  color: #000 !important;
}

.menu__button {
  position: absolute;
  display: none;
  right: 6vw;
  color: var(--primary-color);
  ;
  display: none;
  background-color: transparent;

}

.menu-left {
  display: flex;
  position: absolute;
  margin: 0px;
  left: 4vw;
  gap: 4vw;
  top: 2.2rem;
}

.menu-left2 {
  display: flex;

  margin: 0px;

  gap: 4vw;

}

.menu {
  display: flex;
  position: absolute;
  margin: 0px;
  right: 4vw;
  gap: 4vw;
  top: 2.2rem;
}

.menu2 {
  display: flex;

  margin: 0px;

  gap: 4vw;

}

.navItem {
  color: #646464;


  font-weight: 300;
  font-style: normal;
  font-size: 8px;
  letter-spacing: .6em;
  text-transform: uppercase;
}

.navItem:hover {
  text-decoration: none;
  color: #a2a2a2;
}

.menu__button:focus,
.menu__button:hover {
  /* color: var(--text-color); */
  box-shadow: none;
}

.menu__button svg {
  width: 2em;
  height: 2em;
  fill: black;
  color: black;
}

.nav_ac {
  padding: 5px 15px;
  margin: 0;
  border: unset;
  font-size: 1.25rem;
  font-family: CustomFont !important;

  line-height: 2;
  height: 50px;
  font-weight: bold;
  z-index: 1000;
}

.toogle {
  font-size: 20px;

}

.nav_ac:hover {
  color: #000;
}

.br-top,
.br-bottom,
.br-right,
.br-left {
  position: fixed;
  z-index: 999999;
  background: var(--primary-color);
}

.br-top {
  top: 0;
  height: 10px;
  left: 0;
  width: 100%;
}

.br-bottom {
  bottom: 0;
  left: 0;
  height: 10px;
  width: 100%;
}

.br-right {
  width: 10px;
  right: 0;
  top: 0;
  height: 100%;
}

.br-left {
  width: 10px;
  left: 0;
  top: 0;
  height: 100%;
}

.cortina__wrapper-menu {
  position: relative;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 3em;
  height: 100%;
  overflow-y: auto;
}

.site__navigation {
  height: 100%;
  z-index: 1;
  right: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 30vw;
  visibility: hidden;
}

.menu__opend {
  visibility: visible !important;
}

.main__menu_ul,
.menu_right {
  opacity: 0;
  position: relative;
  transition: 0.5s;
  transition-delay: 0s;
  visibility: hidden;
  z-index: 100;
}

.menu_right {
  text-align: center;
}

.site__navigation.menu__opend .main__menu_ul,
.site__navigation.menu__opend .menu_right {
  opacity: 1;
  transition-delay: 0.6s;
  visibility: visible;
}

.site__navigation .main__menu_ul li {
  list-style: none;
  margin: 10px 0;
}

.site__navigation .main__menu_ul li a {
  color: var(--text-color);
  display: block;
  font-size: 2.5rem;
  text-decoration: none;
}

.bg__menu {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30vw;
  height: 100%;
  background-color: var(--primary-color);
  will-change: transform;
  transform: translateX(100%);
  transition: 1s ease all;
  opacity: 1
}

.menu__opend .bg__menu {
  transform: translateY(0);
  fill: black;
}



.menu__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

.the_menu {
  padding-top: 14vh;

  padding-left: 0;
}

.menu__container {
  display: flex;
  justify-content: center;
}

.menu_item {
  display: flex;
  justify-content: center;
}

.the_menu .menu_item>a {
  color: var(--text-color-2);
  line-height: 1;
  font-size: 3vw;
  display: inline-block;
  position: relative;
  transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s;
  padding: 4px 0px;
  font-family: Marcellus;
}


.menu_footer {
  bottom: 0;
  font-family: Marcellus;
  font-size: 8px;
  background: var(--primary-color);
}

.menu_footer a {
  color: var(--text-color-2);
  margin-right: 10px;
}



@media screen and (max-width: 992px) {
  .menu__container {
    display: flex;
    justify-content: center;
  }

  .menu {
    gap: 3vw;
  }

  .navItem {
    font-size: 1.1rem;
  }

  .menu__button svg {
    width: 1.9em;
    height: 1.9em;
  }


  b {
    position: absolute;
    top: 18px;
    right: 40%;
  }

}

.dark svg {
  fill: black;
}


@media screen and (max-width: 868px) {
  .menu {
    display: none
  }

  .menu-left {
    display: none;
  }

  .menu__button {
    display: block;
  }

  .menu-left2 {
    display: none
  }

  .menu2 {
    display: none;
  }

  .the_menu .menu_item>a {
    font-size: 3vw;
  }

  .logo img {
    max-width: 100px;
  }


  .menu__button svg {
    width: 1.7em;
    height: 1.7em;
  }

  b {
    position: absolute;
    top: 16px;
    right: 37%;
  }

}

@media (max-width: 425px) {

  .site__navigation {
    width: 50vw;
  }

  .bg__menu {
    width: 50vw;
  }

  .the_menu .menu_item>a {

    font-size: 7vw;
  }

  .logo img {

    max-width: 70px;
  }

  .menu__button {
    right: 0;
  }

  .menu__button svg {
    width: 1.5em;
    height: 1.5em;
  }

  b {
    position: absolute;
    top: 15px;
    right: 35%;
  }


}