.po_items_ho {
    /* column-width: 15em;
    -moz-column-width: 15em;
    -webkit-column-width: 15em;
    column-gap: 4rem;
    -moz-column-gap: 4rem;
    -webkit-column-gap: 4rem; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

/* .grid-item {
    width: 100%;
    height: auto;
  } */
  

  .grid-container {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: 2fr 1fr repeat(3, 1fr 2fr);
  grid-template-rows: repeat(3, 100px);
  grid-gap: 10px;
}
.grid-item {
    width: 100%;
    height:100%;
  }

/* .grid-item:nth-child(2) {
    grid-column: span 2;
  } */
/* .grid-item:nth-child(n+2) {
 
} */
/* :nth-child(2n+1):not(:nth-child(2n+2)) {
    grid-column: span 2;
} */
/* .po_item {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    transition: 0.3s ease;
    font-size: 0;
    max-height: 300px;
} */
.po_item {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: auto auto;
    position: relative;
    background: var(--secondary-color);
    padding: 3px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
}

.po_item img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: cover;
}

.po_item .content {
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--overlay-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
    border: 1px solid black;
}

.po_item .content {
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
}

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 1;
}

.po_item:hover .content {
    opacity: 1;
    color: var(--secondary-color);
}

.po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
}

.po_item .content a:hover {
    text-decoration: none;
}

.modal{
    margin:0;
    max-width: none;
    justify-content: center;
    
}
.modal > .modal-content{
    width:80vw;
    height: 80vh;
    border-radius: 5px;
    
}
.modalBody{
    padding:2px
}
.close-button {
    color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    
}
.close-button:hover{
    color:#fff
}